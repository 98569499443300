import { getDateString } from '../state/dateUtils';
import { calculateTotalScore } from '../state/puzzleUtils';
import { MatchMarker, PuzzleData } from '../state/types';
import { getAllPuzzles } from './gameHistoryDB';

export type GameHistoryData = {
    bestResults: {
        highestScore: {
            score: number;
            date: string;
            index: number;
        };
        fastestCompletion: {
            time: number;
            date: string;
            index: number;
        };
    };
    totals: {
        puzzlesCompleted: number;
        tilesUsed: number;
        matchesCompleted: number;
        pointsScored: number;
    };
    puzzlesByDate: {
        [date: string]: PuzzleData;
    };
    streaks: {
        current: number;
        longest: number;
    };
    commonWords: {
        word: string;
        count: number;
    }[];
};

const calculateBestResults = (puzzles: PuzzleData[]): GameHistoryData['bestResults'] => {
    const highestScore = puzzles.reduce(
        (acc, puzzle) => {
            const score = calculateTotalScore(puzzle);
            if (score > acc.score) {
                acc.score = score;
                acc.date = getDateString(puzzle.info.date);
                acc.index = puzzle.info.index;
            }
            return acc;
        },
        { score: 0, date: '', index: 0 },
    );

    const fastestCompletion = puzzles.reduce(
        (acc, puzzle) => {
            if (puzzle.time < acc.time) {
                acc.time = puzzle.time;
                acc.date = getDateString(puzzle.info.date);
                acc.index = puzzle.info.index;
            }
            return acc;
        },
        { time: Infinity, date: '', index: 0 },
    );

    return {
        highestScore,
        fastestCompletion,
    };
};

/**
 * Determines the completed state for puzzles per date
 */
const calculatePuzzlesByDate = (puzzles: PuzzleData[]): GameHistoryData['puzzlesByDate'] => {
    return puzzles.reduce(
        (acc, puzzle) => {
            acc[getDateString(puzzle.info.date)] = puzzle;
            return acc;
        },
        {} as GameHistoryData['puzzlesByDate'],
    );
};

/**
 * Determines the number of games completed,
 * Current streak: the number of games completed in a row, starting from the most recent game
 * Longest streak: the longest number of games completed in a row
 */
const calculateStreaks = (puzzles: PuzzleData[]): GameHistoryData['streaks'] => {
    // for each puzzle, check if the previous puzzle was completed
    const streaks: {
        startIndex: number;
        length: number;
    }[] = puzzles
        .reduceRight(
            (acc, puzzle, index) => {
                const previousPuzzle = puzzles[index + 1];
                const streak = acc[0];

                if (previousPuzzle && previousPuzzle.info.index === puzzle.info.index + 1) {
                    streak.length++;
                } else {
                    acc.unshift({ startIndex: puzzle.info.index, length: 1 });
                }

                return acc;
            },
            [
                {
                    startIndex: puzzles[puzzles.length - 1]?.info.index || 0,
                    length: 0,
                },
            ],
        )
        .filter((s) => s.length > 0);

    const currentStreak = streaks[streaks.length - 1].length;
    const longestStreak = Math.max(...streaks.map((s) => s.length));

    return {
        current: currentStreak,
        longest: longestStreak,
    };
};

const getMatchMarkerLength = (marker: MatchMarker): number => marker.tiles.length;
const getPuzzleTilesUsed = (puzzle: PuzzleData): number =>
    puzzle.matchMarkers.reduce((acc, m) => acc + getMatchMarkerLength(m), 0);

const calculateTotals = (puzzles: PuzzleData[]): GameHistoryData['totals'] => {
    const tilesUsed = puzzles.reduce((acc, p) => acc + getPuzzleTilesUsed(p), 0);
    const matchesCompleted = puzzles.reduce((acc, p) => acc + p.matchMarkers.length, 0);
    const pointsScored = puzzles.reduce((acc, p) => acc + calculateTotalScore(p), 0);

    return {
        puzzlesCompleted: puzzles.length,
        tilesUsed,
        matchesCompleted,
        pointsScored,
    };
};

const calculateCommonWords = (puzzles: PuzzleData[]): GameHistoryData['commonWords'] => {
    const wordCounts = puzzles.reduce(
        (acc, p) => {
            p.matchMarkers.forEach((m) => {
                acc[m.word] = (acc[m.word] || 0) + 1;
            });

            return acc;
        },
        {} as Record<string, number>,
    );

    const commonWords = Object.entries(wordCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([word, count]) => ({ word, count }))
        .slice(0, 5);

    return commonWords;
};

export const getGameHistoryData = async (): Promise<GameHistoryData> => {
    const puzzles = await getAllPuzzles(10000);

    if (puzzles.length === 0) {
        return {
            puzzlesByDate: {},
            bestResults: {
                highestScore: {
                    score: 0,
                    date: '',
                    index: 0,
                },
                fastestCompletion: {
                    time: Infinity,
                    date: '',
                    index: 0,
                },
            },
            streaks: {
                current: 0,
                longest: 0,
            },
            totals: {
                puzzlesCompleted: 0,
                tilesUsed: 0,
                matchesCompleted: 0,
                pointsScored: 0,
            },
            commonWords: [],
        };
    }

    const puzzlesByDate = calculatePuzzlesByDate(puzzles);
    const bestResults = calculateBestResults(puzzles);
    const streaks = calculateStreaks(puzzles);
    const totals = calculateTotals(puzzles);
    const commonWords = calculateCommonWords(puzzles);

    return {
        puzzlesByDate,
        bestResults,
        streaks,
        totals,
        commonWords,
    };
};
