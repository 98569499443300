import seedRandom from 'seedrandom';
import commonWords from '../data/common-words.json';
import { nanoid } from 'nanoid';

import { MatchMarker, PuzzleData, Tile, WordMatch } from '../state/types';
import { GRID_WIDTH } from '../state/gameConstants';
import { determineMatchMarkerComplete, getMatchMarkerTiles } from '../state/matchUtils';
import { calculatePuzzleDifficulty } from './puzzleDifficultyUtils';

// The alphabet, ordered by frequency of use in the English language
const ALPHABET = 'etaoinsrhldcumfpgwybvkxjqz';

const mean = (values: number[]): number => values.reduce((a, b) => a + b, 0) / values.length;

const findFromIndex = <T>(list: T[], startIndex: number, predicate: (word: T) => boolean): T | undefined => {
    return list.slice(startIndex).find(predicate) || list.slice(0, startIndex).find(predicate);
};

const wordKeyFromLength = (length: number): '2' | '3' | '4' | '5' | '6' => {
    if (length < 2) return '2';
    if (length > 6) return '6';
    return length.toString() as '2' | '3' | '4' | '5' | '6';
};

const findEmptySpace = (tiles: Tile[]): Tile | undefined => {
    for (let x = 0; x < GRID_WIDTH; x++) {
        for (let y = 0; y < GRID_WIDTH; y++) {
            const tile = tiles.find((t) => t.x === x && t.y === y);
            if (!tile) return { id: '', x, y, letter: '' };
        }
    }
};

const createMatchMarkers = (matches: WordMatch[]): MatchMarker[] => {
    return matches.map((match) => {
        const startTile = match.tiles[0];

        const length = match.tiles.length;

        return {
            id: match.id,
            x: startTile.x,
            y: startTile.y,
            word: match.word,
            length: length,
            direction: match.direction,
            tiles: match.tiles,
        };
    });
};

const createWordTiles = (word: string, x: number, y: number, direction: 'horizontal' | 'vertical'): Tile[] => {
    return word.split('').map((letter, index) => ({
        letter,
        id: nanoid(5),
        x: direction === 'horizontal' ? x + index : x,
        y: direction === 'horizontal' ? y : y + index,
    }));
};

export const generatePuzzleData = (
    seed: string,
    wordCounts: number[],
    lockCount: number,
    info: {
        date: Date;
        index: number;
    },
): PuzzleData => {
    const rng = seedRandom(seed);

    const randInt = (min: number, max: number): number => Math.floor(rng() * (max - min + 1)) + min;
    const shuffleTiles = (array: Tile[], swapCount: number = 1000): Tile[] => {
        const shuffled = [...array];

        for (let i = 0; i < swapCount; i++) {
            const indexA = randInt(0, shuffled.length - 1);
            const indexB = randInt(0, shuffled.length - 1);

            const tileA = shuffled[indexA];
            const tileB = shuffled[indexB];

            if (tileA.locked || tileB.locked) continue;

            shuffled[indexA] = {
                ...tileB,
                x: tileA.x,
                y: tileA.y,
            };

            shuffled[indexB] = {
                ...tileA,
                x: tileB.x,
                y: tileB.y,
            };
        }

        return shuffled;
    };

    const lockTiles = (tiles: Tile[], lockCount: number): Tile[] => {
        const withLocks = [...tiles];

        let lockedTiles = 0;
        while (lockedTiles < lockCount) {
            const index = randInt(0, withLocks.length - 1);

            if (withLocks[index].locked) continue;

            // ensure no adjacent tiles are locked
            const adjacentTiles = [
                { x: withLocks[index].x - 1, y: withLocks[index].y },
                { x: withLocks[index].x + 1, y: withLocks[index].y },
                { x: withLocks[index].x, y: withLocks[index].y - 1 },
                { x: withLocks[index].x, y: withLocks[index].y + 1 },
            ];

            const adjacentLocked = adjacentTiles.some((coords) => {
                const adjacentTile = withLocks.find((t) => t.x === coords.x && t.y === coords.y);

                return adjacentTile?.locked;
            });

            if (adjacentLocked) continue;

            withLocks[index] = {
                ...withLocks[index],
                locked: true,
            };

            lockedTiles++;
        }

        return withLocks;
    };

    const getLetter = (randomVal: number): string => {
        return ALPHABET.charAt(Math.floor(randomVal * ALPHABET.length));
    };

    const pickWeightedLetter = (): string => getLetter(rng() * rng());
    const pickReverseWeightedLetter = (): string => getLetter(1 - rng() * rng());

    // deep copy the word lists, so we can modify them without affecting the original
    const wordLists = {
        2: [...commonWords['2']],
        3: [...commonWords['3']],
        4: [...commonWords['4']],
        5: [...commonWords['5']],
        6: [...commonWords['6']],
    };

    const getWordDirection = (lastMatch: WordMatch | undefined): 'horizontal' | 'vertical' => {
        if (!lastMatch) return rng() > 0.5 ? 'horizontal' : 'vertical';

        return lastMatch.direction === 'horizontal' ? 'vertical' : 'horizontal';
    };

    const getWordPosition = (
        wordLength: number,
        direction: 'horizontal' | 'vertical',
    ): {
        x: number;
        y: number;
    } => {
        const constrainedLength = Math.max(GRID_WIDTH - wordLength, 0);

        if (direction === 'horizontal') {
            return {
                x: randInt(0, constrainedLength),
                y: randInt(0, GRID_WIDTH - 1),
            };
        }

        return {
            x: randInt(0, GRID_WIDTH - 1),
            y: randInt(0, constrainedLength),
        };
    };

    const createCrossword = (
        wordLength: number,
        direction: 'horizontal' | 'vertical',
        existingWords: WordMatch[],
    ): WordMatch | undefined => {
        const tiles = existingWords.flatMap((word) => word.tiles);

        let attempts = 0;
        while (attempts < 1000) {
            attempts++;

            const { x, y } = getWordPosition(wordLength, direction);
            const wordListKey = wordKeyFromLength(wordLength);
            const wordList = wordLists[wordListKey];

            // find a word where all tiles either have no intersection with the current tiles or intersect with the same letter
            const word = findFromIndex(wordList, randInt(0, wordList.length - 1), (word) => {
                if (word.length !== wordLength) return false;

                const wordTiles = createWordTiles(word, x, y, direction);

                return wordTiles.every((tile) => {
                    const existingTile = tiles.find((t) => t.x === tile.x && t.y === tile.y);

                    return !existingTile || existingTile.letter === tile.letter;
                });
            });

            if (!word) continue;

            // ensure the word doesn't intersect with any other words going in the same direction
            const intersectsSameDirection = existingWords.some((match) => {
                if (match.direction !== direction) return false;

                return match.tiles.some((tile) => {
                    const existingTile = createWordTiles(word, x, y, direction).find(
                        (t) => t.x === tile.x && t.y === tile.y,
                    );

                    return !!existingTile;
                });
            });

            if (intersectsSameDirection) continue;

            // ensure the word has at least one intersection with another word
            const intersectsOtherWord = existingWords.some((match) => {
                return match.tiles.some((tile) => {
                    return createWordTiles(word, x, y, direction).some((t) => t.x === tile.x && t.y === tile.y);
                });
            });

            if (existingWords.length > 2 && !intersectsOtherWord && attempts < 100) continue;

            // ensure the word won't exceed the grid bounds
            const wordTiles = createWordTiles(word, x, y, direction);
            if (wordTiles.some((tile) => tile.x < 0 || tile.x >= GRID_WIDTH || tile.y < 0 || tile.y >= GRID_WIDTH)) {
                continue;
            }

            // remove the word from the list of available words
            wordList.splice(wordList.indexOf(word), 1);

            return {
                id: nanoid(5),
                word,
                tiles: wordTiles,
                direction,
            };
        }
    };

    /**
     * Creates a list of word matches that will be used as the puzzle's solution.
     * Each word match contains the word, the tiles that make up the word, and the direction of the word.
     */
    const createCrosswords = (wordLengths: number[]): WordMatch[] => {
        const matches: WordMatch[] = [];

        for (const wordLength of wordLengths) {
            const direction = getWordDirection(matches[matches.length - 1]);

            const match = createCrossword(wordLength, direction, matches);

            if (match) {
                matches.push(match);
            }
        }

        return matches;
    };

    /**
     * Fill the board with random words, until the maximum size is reached.
     */
    const fillBoardTiles = (tiles: Tile[], maxSize: number): Tile[] => {
        const returnedTiles = [...tiles];

        let emptySpace = findEmptySpace(returnedTiles);

        // add one less common letter to the board
        returnedTiles.push({
            letter: pickReverseWeightedLetter(),
            id: nanoid(5),
            x: emptySpace?.x || 0,
            y: emptySpace?.y || 0,
        });

        while (returnedTiles.length < maxSize) {
            emptySpace = findEmptySpace(returnedTiles);
            returnedTiles.push({
                letter: pickWeightedLetter(),
                id: nanoid(5),
                x: emptySpace?.x || 0,
                y: emptySpace?.y || 0,
            });
        }

        return returnedTiles;
    };

    const matches = createCrosswords(wordCounts);
    const matchMarkers = createMatchMarkers(matches);

    const matchTiles = matches
        .flatMap((match) => match.tiles)
        .filter((tile, index, self) => {
            return self.findIndex((t) => t.x === tile.x && t.y === tile.y) === index;
        });

    const matchTilesWithLocks = lockTiles(matchTiles, lockCount);

    const allTiles = fillBoardTiles(matchTilesWithLocks, GRID_WIDTH * GRID_WIDTH);

    // shuffle the tiles until there are no complete matches
    let shuffledTiles = shuffleTiles(allTiles);

    let hasCompleteMatches = matchMarkers.some((marker) => determineMatchMarkerComplete(marker, shuffledTiles));

    while (hasCompleteMatches) {
        shuffledTiles = shuffleTiles(shuffledTiles);
        // eslint-disable-next-line no-loop-func
        hasCompleteMatches = matchMarkers.some((marker) => determineMatchMarkerComplete(marker, shuffledTiles));
    }

    const resolvedMatchMarkers = matchMarkers.map((marker) => {
        const tiles = getMatchMarkerTiles(marker, shuffledTiles);

        return {
            ...marker,
            tiles,
        };
    });

    const difficulty = calculatePuzzleDifficulty(shuffledTiles, matchTiles, matchMarkers);

    return {
        seed,
        difficulty,
        matchMarkers: resolvedMatchMarkers,
        tiles: shuffledTiles,
        info,
        complete: false,
        time: 0,
        originalTiles: allTiles,
    };
};
