import React from 'react';

import './StyledButton.scss';

interface StyledButtonProps {
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

const StyledButton = ({ className, onClick, children }: StyledButtonProps): React.ReactElement => {
    return (
        <button className={`StyledButton ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default StyledButton;
