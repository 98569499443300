import { openDB, DBSchema } from 'idb';
import { PuzzleData } from '../state/types';

const DB_NAME = 'gingham.history';
const DB_STORE = 'puzzles';

interface PuzzleHistoryDb extends DBSchema {
    puzzles: {
        value: PuzzleData;
        key: string;
        indexes: { seed: string; date: Date; index: number };
    };
}

const dbPromise = openDB<PuzzleHistoryDb>(DB_NAME, 1, {
    upgrade(db) {
        const store = db.createObjectStore(DB_STORE);
        store.createIndex('seed', 'seed', { unique: true });
        store.createIndex('date', 'info.date', { unique: true });
        store.createIndex('index', 'info.index', { unique: false });
    },
});

async function get(key: string): Promise<PuzzleData | undefined> {
    return (await dbPromise).get(DB_STORE, key);
}

async function set(key: string, val: PuzzleData) {
    return (await dbPromise).put(DB_STORE, val, key);
}

export const getPuzzle = async (seed: string): Promise<PuzzleData | undefined> => {
    return get(seed);
};

export const getAllPuzzles = async (count: number): Promise<PuzzleData[]> => {
    const db = await dbPromise;
    const puzzles = await db.getAllFromIndex(DB_STORE, 'index', null, count);
    return puzzles;
};

export const savePuzzle = async (puzzle: PuzzleData) => {
    await set(puzzle.seed, puzzle);
};
