import React, { useEffect, useRef } from 'react';

import ReactGA from 'react-ga4';

import { createPuzzle } from './gameCreation/createPuzzle';
import { getDateString } from './state/dateUtils';
import { savePuzzle } from './gameHistory/gameHistoryDB';

import GameBoard from './components/GameBoard';
import HelpModal from './modals/HelpModal';
import SettingsModal from './modals/SettingsModal';
import VictoryModal from './modals/VictoryModal';
import PuzzleDescription from './components/PuzzleDescription';
import PuzzleStatus from './components/PuzzleStatus';
import AppHeader from './components/AppHeader';
import StatsModal from './modals/StatsModal';

import useMeasureRef from './hooks/useMeasureRef';
import useGameState from './state/useGameState';
import useBodyClass from './hooks/useBodyClass';
import useGameTimer from './hooks/useGameTimer';

import { Action, GameState } from './state/types';
import { GRID_WIDTH } from './state/gameConstants';

import './App.scss';

const DebugTools = React.lazy(() => import('./modals/DebugTools'));
const SuccessConfetti = React.lazy(() => import('./components/SuccessConfetti'));

ReactGA.initialize('G-CP4YXVQJB0');

const useCurrentPuzzleManager = (
    state: GameState,
    dispatch: React.Dispatch<Action>,
    {
        handleCloseModal,
    }: {
        handleCloseModal: (modalId: keyof GameState['modals']) => void;
    },
) => {
    const { puzzleDate, puzzle } = state;

    const handleResetPuzzle = (todaysPuzzleDate: string) => {
        dispatch({ type: 'set_puzzle_date', date: todaysPuzzleDate });
    };

    useEffect(() => {
        const todaysPuzzleDate = getDateString(new Date());

        if (todaysPuzzleDate !== puzzleDate) {
            handleResetPuzzle(todaysPuzzleDate);
        }
    }, []);

    useEffect(() => {
        const currentPuzzleDate = getDateString(new Date(puzzle.info?.date));
        if (puzzle.tiles.length && puzzleDate === currentPuzzleDate) return;

        dispatch({ type: 'set_puzzle', puzzle: createPuzzle(puzzleDate) });
        handleCloseModal('victory');

        ReactGA.event({
            category: 'Game',
            action: 'Puzzle Started',
            label: getDateString(new Date(puzzleDate)),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzle.info?.index, puzzleDate]);

    useEffect(() => {
        if (puzzle.complete) {
            savePuzzle(puzzle);
        }
    }, [puzzle.complete, puzzle]);
};

function App() {
    const appContainerRef = useRef<HTMLDivElement>(null);

    const [state, dispatch] = useGameState(true);

    const appContainerSize = useMeasureRef(appContainerRef);

    const { puzzle, activeTileId, settings, modals } = state;

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    useGameTimer(!puzzle.complete, puzzle.time, dispatch);

    useBodyClass(`theme-${settings.theme}`);

    const handleDragStart = (tileId: string) => {
        dispatch({ type: 'select_tile', tileId: null });
    };

    const handleTileSwap = (sourceId: string, targetId: string) => {
        dispatch({ type: 'swap_tile', sourceId: sourceId, targetId: targetId });
        dispatch({ type: 'select_tile', tileId: null });
    };

    const handleTileTap = (tileId: string) => {
        if (activeTileId === tileId) {
            dispatch({ type: 'select_tile', tileId: null });
            return;
        }

        if (activeTileId) {
            handleTileSwap(activeTileId, tileId);
            dispatch({ type: 'select_tile', tileId: null });
            return;
        }

        dispatch({ type: 'select_tile', tileId });
    };

    const handleOpenModal = (modalId: keyof GameState['modals']) => {
        dispatch({ type: 'set_modal_active', modal: modalId, open: true });
    };

    const handleCloseModal = (modalId: keyof GameState['modals']) => {
        dispatch({ type: 'set_modal_active', modal: modalId, open: false });
    };

    const handleCompletePuzzle = () => {
        dispatch({ type: 'set_puzzle_complete', complete: true });

        ReactGA.event({
            category: 'Game',
            action: 'Puzzle Completed',
            label: getDateString(new Date(puzzle.info.date)),
        });

        setTimeout(() => {
            handleOpenModal('victory');
        }, 4000);
    };

    useCurrentPuzzleManager(state, dispatch, {
        handleCloseModal,
    });

    const appWidth = Math.min(appContainerSize?.width || 0, 500);
    const tileSize = Math.min((appWidth - 30) / GRID_WIDTH, 100);

    return (
        <>
            {process.env.NODE_ENV === 'development' && <DebugTools state={state} dispatch={dispatch} />}

            <SuccessConfetti
                active={puzzle.complete && getDateString(new Date()) === getDateString(new Date(puzzle.info.date))}
            />

            {modals.help && <HelpModal handleCloseModal={handleCloseModal} />}
            {modals.settings && <SettingsModal handleCloseModal={handleCloseModal} state={state} dispatch={dispatch} />}
            {modals.victory && (
                <VictoryModal handleCloseModal={handleCloseModal} gameState={state} gameTime={state.puzzle.time} />
            )}
            {modals.stats && <StatsModal handleCloseModal={handleCloseModal} />}

            <div className="App" ref={appContainerRef}>
                <AppHeader puzzle={puzzle} handleOpenModal={handleOpenModal} />

                <div className="main-game-container">
                    <PuzzleDescription puzzle={puzzle} />

                    <div className="game-board-container">
                        {tileSize > 0 && (
                            <GameBoard
                                puzzle={puzzle}
                                tileSize={tileSize}
                                activeTileId={activeTileId}
                                interactive={!puzzle.complete}
                                handleTileTap={handleTileTap}
                                handleTileSwap={handleTileSwap}
                                handleDragStart={handleDragStart}
                            />
                        )}
                    </div>

                    <PuzzleStatus
                        puzzle={puzzle}
                        handleCompletePuzzle={handleCompletePuzzle}
                        handleOpenVictoryModal={() => {
                            handleOpenModal('victory');
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default App;
