import words from '../data/words.json';

import { GRID_WIDTH } from './gameConstants';
import { getTilesInColumn, getTilesInRow } from './tileUtils';

import { MatchMarker, Tile, WordMatch } from './types';

export const testTilesForWord = (tiles: Tile[], minLength: number = 2): string | undefined => {
    if (tiles.length < minLength) return;

    const word = tiles.map((tile) => tile.letter).join('');
    return words.includes(word) ? word : undefined;
};

const findHorizontalMatch = (tiles: Tile[], x: number, y: number): WordMatch | undefined => {
    const horizontalTiles = getTilesInRow(tiles, x, y);

    while (horizontalTiles.length > 1) {
        const word = testTilesForWord(horizontalTiles);

        if (word) {
            return {
                id: `${word}-${x}-${y}`,
                word,
                tiles: horizontalTiles,
                direction: 'horizontal',
            };
        }

        horizontalTiles.pop();
    }
};

const findVerticalMatch = (tiles: Tile[], x: number, y: number): WordMatch | undefined => {
    const verticalTiles = getTilesInColumn(tiles, x, y);

    while (verticalTiles.length > 1) {
        const word = testTilesForWord(verticalTiles);

        if (word) {
            return {
                id: `${word}-${x}-${y}`,
                word,
                tiles: verticalTiles,
                direction: 'vertical',
            };
        }

        verticalTiles.pop();
    }
};

const findMatchesInDir = (
    tiles: Tile[],
    findMatchInDirectionFn: (tiles: Tile[], x: number, y: number) => WordMatch | undefined,
): WordMatch[] => {
    const matches: WordMatch[] = [];

    // find all matches in the given direction
    for (let y = 0; y < GRID_WIDTH; y++) {
        for (let x = 0; x < GRID_WIDTH; x++) {
            const match = findMatchInDirectionFn(tiles, x, y);
            if (!match) continue;

            matches.push(match);
        }
    }

    // sort matches by length
    matches.sort((a, b) => b.word.length - a.word.length);

    // remove any matches that are substrings of a longer match
    const usedTiles = new Set<Tile>();
    const uniqueMatches = matches.filter((match) => {
        for (const tile of match.tiles) {
            if (usedTiles.has(tile)) {
                return false;
            }
        }

        match.tiles.forEach((tile) => usedTiles.add(tile));
        return true;
    });

    return uniqueMatches;
};

export const calculateWordMatches = (tiles: Tile[]): WordMatch[] => {
    return [...findMatchesInDir(tiles, findHorizontalMatch), ...findMatchesInDir(tiles, findVerticalMatch)];
};

const findFirstMatchInDir = (
    tiles: Tile[],
    findMatchInDirectionFn: (tiles: Tile[], x: number, y: number) => WordMatch | undefined,
): WordMatch | undefined => {
    for (let y = 0; y < GRID_WIDTH; y++) {
        for (let x = 0; x < GRID_WIDTH; x++) {
            const match = findMatchInDirectionFn(tiles, x, y);

            if (match) return match;
        }
    }
};

export const findFirstMatch = (tiles: Tile[]): WordMatch | undefined => {
    return findFirstMatchInDir(tiles, findHorizontalMatch) || findFirstMatchInDir(tiles, findVerticalMatch);
};

export const getMatchMarkerTiles = (marker: MatchMarker, tiles: Tile[]): Tile[] => {
    return marker.direction === 'horizontal'
        ? getTilesInRow(tiles, marker.x, marker.y, marker.length)
        : getTilesInColumn(tiles, marker.x, marker.y, marker.length);
};

export const determineMatchMarkerComplete = (marker: MatchMarker, tiles: Tile[]): boolean => {
    const matchTiles = getMatchMarkerTiles(marker, tiles);
    return testTilesForWord(matchTiles) !== undefined;
};

export const getMatchScore = (match: WordMatch): number => {
    switch (match.word.length) {
        case 3:
            return 1;
        case 4:
            return 3;
        case 5:
            return 6;
        case 6:
            return 10;
        default:
            return 0;
    }
};
