import classNames from 'classnames';
import { getPuzzleStatus } from '../state/puzzleUtils';

import { ReactComponent as SettingsIcon } from '../icons/icon-settings.svg';
import { ReactComponent as HelpIcon } from '../icons/icon-help.svg';
import { ReactComponent as StatsIcon } from '../icons/icon-stats.svg';

import { GameState, PuzzleData } from '../state/types';

import './AppHeader.scss';

const AppHeader = ({
    puzzle,
    handleOpenModal,
}: {
    puzzle: PuzzleData;
    handleOpenModal: (modalId: keyof GameState['modals']) => void;
}) => {
    const status = getPuzzleStatus(puzzle);

    return (
        <div className="AppHeader">
            <div className="tools tools-left">
                <button className="help-button" onClick={() => handleOpenModal('help')}>
                    <HelpIcon className="icon" />
                </button>
            </div>

            <div className="header-title">
                <h1 className={classNames('title', status)}>
                    GINGHAM
                    <div className="bar horizontal" />
                </h1>
            </div>

            <div className="tools tools-right">
                <button className="theme-button" onClick={() => handleOpenModal('stats')}>
                    <StatsIcon className="icon" />
                </button>
                <button className="theme-button" onClick={() => handleOpenModal('settings')}>
                    <SettingsIcon className="icon" />
                </button>
            </div>
        </div>
    );
};

export default AppHeader;
