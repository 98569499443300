import { GameHistoryData } from '../gameHistory/gameHistoryUtils';
import { getDateString } from '../state/dateUtils';
import { PuzzleData } from '../state/types';
import GameBoard from './GameBoard';

import { ReactComponent as XIcon } from '../icons/icon-close.svg';

import './GameHistoryDays.scss';
import { calculateTotalScore } from '../state/puzzleUtils';

const GameHistoryPuzzle = ({ date, puzzle }: { date: string; puzzle: PuzzleData | undefined }) => {
    const score = puzzle?.complete ? calculateTotalScore(puzzle) : 0;

    const puzzleDate = new Date(date);
    const formattedDate = puzzleDate.toLocaleDateString(navigator.languages, {
        month: '2-digit',
        day: '2-digit',
    });

    return (
        <div className="puzzle-history complete">
            <div className="board-wrapper">
                <div className="board">
                    {puzzle && puzzle.complete ? (
                        <GameBoard
                            puzzle={puzzle}
                            tileSize={5}
                            boardSize={6}
                            activeTileId={null}
                            interactive={false}
                            handleTileTap={() => {}}
                            handleTileSwap={() => {}}
                            handleDragStart={() => {}}
                        />
                    ) : (
                        <div className="empty-board" style={{ width: 5 * 6, height: 5 * 6 }}>
                            <XIcon className="icon" />
                        </div>
                    )}
                </div>
            </div>

            <div className="puzzle-history-info">
                <div className="puzzle-history-index">{formattedDate}</div>
                {score > 0 && <div className="puzzle-history-score">{score}</div>}
            </div>
        </div>
    );
};

const setToMonday = (date: Date): Date => {
    var day = date.getDay() || 7;

    if (day !== 1) {
        date.setHours(-24 * (day - 1));
    }

    return date;
};

const getWeekDates = (date: Date): string[] => {
    const dates: string[] = [];

    for (let i = 0; i < 7; i++) {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + i);
        dates.push(getDateString(newDate));
    }

    return dates;
};

const GameHistoryDays = ({
    gameHistoryData,
    numberOfDays = 7,
}: {
    gameHistoryData: GameHistoryData;
    numberOfDays: number;
}) => {
    // start this monday
    const weekDates = getWeekDates(setToMonday(new Date()));

    const weeklyPuzzles = weekDates.map((date) => ({
        date,
        puzzle: gameHistoryData.puzzlesByDate[date],
    }));

    return (
        <div
            className="GameHistoryDays"
            style={{
                gridTemplateColumns: `repeat(${numberOfDays}, 1fr)`,
            }}
        >
            {weeklyPuzzles.slice(-numberOfDays).map(({ puzzle, date }) => (
                <GameHistoryPuzzle key={date} puzzle={puzzle} date={date} />
            ))}
        </div>
    );
};

export default GameHistoryDays;
