import { getDateString } from './dateUtils';

import { GameState } from './types';

export const EMPTY_TILE = '-';

export const GRID_WIDTH = 6;
export const BOARD_SIZE = GRID_WIDTH * GRID_WIDTH;

export const DEFAULT_STATE: GameState = {
    puzzleDate: getDateString(new Date()),
    puzzle: {
        seed: '',
        difficulty: 0,
        tiles: [],
        matchMarkers: [],
        complete: false,
        time: 0,
        info: {
            date: new Date(),
            index: 0,
        },
    },
    settings: {
        theme: 'light',
    },
    modals: {
        settings: false,
        victory: false,
        help: true,
        stats: false,
    },
};
