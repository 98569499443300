import React, { useEffect, useReducer } from 'react';

import { Action, GameState, SwapTileAction } from './types';
import { getMatchMarkerTiles, testTilesForWord } from './matchUtils';

import { DEFAULT_STATE } from './gameConstants';

const LOCAL_STORAGE_KEY = 'gingham.game.state';

const getDefaultState = (): GameState => {
    const defaultTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    return {
        ...DEFAULT_STATE,
        settings: {
            ...DEFAULT_STATE.settings,
            theme: defaultTheme,
        },
    };
};

const getInitialState = (): GameState => {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!storedState) return getDefaultState();

    const parsedState = JSON.parse(storedState) as GameState;
    const defaultState = getDefaultState();

    return {
        ...defaultState,
        ...parsedState,
        puzzle: {
            ...defaultState.puzzle,
            ...parsedState?.puzzle,
            info: {
                ...defaultState.puzzle.info,
                ...parsedState?.puzzle?.info,
                date: parsedState?.puzzle?.info?.date ? new Date(parsedState?.puzzle?.info?.date) : new Date(),
            },
        },
        settings: {
            ...defaultState.settings,
            ...parsedState?.settings,
        },
        modals: {
            ...defaultState.modals,
            ...parsedState?.modals,
        },
    };
};

const calculateMatchMarkers = (state: GameState): GameState => {
    return {
        ...state,
        puzzle: {
            ...state.puzzle,
            matchMarkers: state.puzzle.matchMarkers.map((marker) => {
                const tiles = getMatchMarkerTiles(marker, state.puzzle.tiles);
                const word = testTilesForWord(tiles) || '';
                const valid = !!testTilesForWord(tiles);

                return {
                    ...marker,
                    tiles: tiles,
                    word,
                    valid,
                };
            }),
        },
    };
};

const handleSwap = (state: GameState, action: SwapTileAction): GameState => {
    const { sourceId, targetId } = action;

    const targetTile = state.puzzle.tiles.find((tile) => tile.id === targetId);
    const sourceTile = state.puzzle.tiles.find((tile) => tile.id === sourceId);

    if (!targetTile || !sourceTile) {
        return state;
    }

    // Swap the tiles
    let updatedState = {
        ...state,
        puzzle: {
            ...state.puzzle,
            tiles: state.puzzle.tiles.map((tile) => {
                if (tile.id === sourceId) {
                    return {
                        ...tile,
                        x: targetTile.x,
                        y: targetTile.y,
                    };
                }

                if (tile.id === targetId) {
                    return {
                        ...tile,
                        x: sourceTile.x,
                        y: sourceTile.y,
                    };
                }

                return tile;
            }),
        },
    };

    return calculateMatchMarkers(updatedState);
};

const stateReducer = (state: GameState, action: Action): GameState => {
    if (process.env.NODE_ENV === 'development' && action.type !== 'tick_timer') {
        console.log('Action:', action);
    }

    switch (action.type) {
        case 'set_puzzle_date':
            return {
                ...state,
                puzzleDate: action.date,
            };
        case 'set_puzzle':
            return calculateMatchMarkers({
                ...state,
                puzzle: action.puzzle,
            });
        case 'swap_tile':
            return handleSwap(state, action);
        case 'select_tile':
            return {
                ...state,
                activeTileId: action.tileId,
            };
        case 'set_theme':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    theme: action.theme,
                },
            };
        case 'set_modal_active':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.modal]: action.open,
                },
            };
        case 'set_puzzle_complete':
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    complete: action.complete,
                },
            };
        case 'tick_timer':
            return {
                ...state,
                puzzle: {
                    ...state.puzzle,
                    time: action.time,
                },
            };
        default:
            return state;
    }
};

const useGameState = (saveToStore: boolean): [GameState, React.Dispatch<Action>] => {
    const [state, dispatch] = useReducer(stateReducer, getInitialState());

    useEffect(() => {
        if (saveToStore) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
        }
    }, [state, saveToStore]);

    return [state, dispatch] as [GameState, React.Dispatch<Action>];
};

export default useGameState;
