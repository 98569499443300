import { RefObject, useState, useLayoutEffect } from 'react';

/**
 * Returns the current size of the element, and updates when the element resizes.
 */
const useMeasureRef = <T extends HTMLElement>(ref: RefObject<T>) => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        const measure = () => {
            if (!ref.current) return;

            const { width, height } = ref.current.getBoundingClientRect();
            setSize({ width, height });
        };

        measure();
        window.addEventListener('resize', measure);
        return () => window.removeEventListener('resize', measure);
    }, [ref]);

    return size;
};

export default useMeasureRef;
