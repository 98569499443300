import React from 'react';
import { useDragLayer } from 'react-dnd';
import { Tile } from '../state/types';
import { getTileScore } from '../state/tileUtils';
import classNames from 'classnames';

import './LetterTileDragLayer.scss';

const LetterTileDragLayer = ({ size }: { size: number }): React.ReactElement | null => {
    const { isDragging, item, position } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
        position: monitor.getSourceClientOffset(),
    }));

    if (!isDragging) return null;

    const { tile } = item as { tile: Tile };

    const fontSize = size * 0.29;
    const style = {
        width: size,
        height: size,
        fontSize,
    } as React.CSSProperties;

    return (
        <div
            className="LetterTileDragLayer"
            style={
                {
                    position: 'absolute',
                    pointerEvents: 'none',
                    zIndex: 100,
                    left: position?.x,
                    top: position?.y,
                    '--tile-size': `${size}px`,
                    '--font-size': `${fontSize}px`,
                } as React.CSSProperties
            }
        >
            <div className={classNames('LetterTile')} style={style}>
                <div className="letter">{tile.letter}</div>
                <div className="score">{getTileScore(tile)}</div>
            </div>
        </div>
    );
};

export default LetterTileDragLayer;
