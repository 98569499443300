import React from 'react';

import OverlayModal from '../components/OverlayModal';
import ToggleSwitchButton from '../components/ToggleSwitchButton';
import StyledButton from '../components/StyledButton';

import { createPuzzle } from '../gameCreation/createPuzzle';
import { getDateString } from '../state/dateUtils';

import { Action, GameState } from '../state/types';

import './SettingsModal.scss';

const EMAIL_LINK = 'mailto:isaac+gingham@isaacwilliams.net?subject=Gingham%20Feedback&body=';

type SettingsModalProps = {
    handleCloseModal: (modalId: keyof GameState['modals']) => void;
    state: GameState;
    dispatch: React.Dispatch<Action>;
};

const SettingsModal = ({ handleCloseModal, state, dispatch }: SettingsModalProps) => {
    const { settings } = state;

    const handleToggleTheme = () => {
        dispatch({ type: 'set_theme', theme: settings.theme === 'light' ? 'dark' : 'light' });
    };

    const handleResetPuzzle = () => {
        const todaysPuzzleDate = getDateString(new Date());
        dispatch({ type: 'set_puzzle', puzzle: createPuzzle(todaysPuzzleDate) });
        handleCloseModal('settings');
    };

    const emailLinkWithDebugDetails = `${EMAIL_LINK}${encodeURIComponent(
        `
-------------

Debug details:

User Agent: ${navigator.userAgent}
Screen size: ${window.screen.width}x${window.screen.height}
Viewport size: ${document.documentElement.clientWidth}x${document.documentElement.clientHeight}
Timezone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}
Theme: ${settings.theme}

Puzzle seed: ${state.puzzle.seed}
Puzzle index: ${state.puzzle.info?.index}
`,
    )}`;

    return (
        <OverlayModal title="Settings" modalId="settings" close={handleCloseModal} className="SettingsModal">
            <div className="settings-row">
                <label>Dark theme</label>

                <ToggleSwitchButton id="theme" on={settings.theme === 'dark'} onClick={handleToggleTheme} />
            </div>
            <div className="settings-row">
                <label>Restart puzzle</label>
                <StyledButton onClick={handleResetPuzzle} className="secondary danger">
                    Reset
                </StyledButton>
            </div>
            <div className="settings-row">
                <label>Feedback / report a bug</label>
                <a href={emailLinkWithDebugDetails} className="StyledButton secondary">
                    Email
                </a>
            </div>
            <div className="settings-row">
                <label>Social</label>
                <a href="https://bsky.app/profile/losing.games" className="StyledButton secondary">
                    Follow me on BlueSky
                </a>
            </div>
            <div className="settings-row">
                <div>
                    © {new Date().getFullYear()}{' '}
                    <a className="basic-link" href="https://losing.games" target="_blank" rel="noreferrer">
                        Losing Games
                    </a>
                    {' • '}
                    <a className="basic-link" href="https://isaacwilliams.net" target="_blank" rel="noreferrer">
                        Isaac Williams
                    </a>
                </div>
            </div>
        </OverlayModal>
    );
};

export default SettingsModal;
