import React from 'react';

import { getPuzzleStatus } from '../state/puzzleUtils';

import StyledButton from './StyledButton';
import PuzzleScores from './PuzzleScores';

import { PuzzleData } from '../state/types';

import './PuzzleStatus.scss';

type PuzzleStatusProps = {
    puzzle: PuzzleData;
    handleCompletePuzzle: () => void;
    handleOpenVictoryModal: () => void;
};

const PuzzleStatus = ({
    puzzle,
    handleCompletePuzzle,
    handleOpenVictoryModal,
}: PuzzleStatusProps): React.ReactElement => {
    const status = getPuzzleStatus(puzzle);

    let buttonText = 'Finished?';
    if (status === 'valid') buttonText = 'Finished?';
    if (status === 'complete') buttonText = 'Complete!';

    let buttonOnClick = () => {};
    if (status === 'valid') buttonOnClick = handleCompletePuzzle;
    if (status === 'complete') buttonOnClick = handleOpenVictoryModal;

    return (
        <div className="PuzzleStatus">
            <PuzzleScores puzzle={puzzle} />

            <StyledButton className={status} onClick={buttonOnClick}>
                {buttonText}
            </StyledButton>
        </div>
    );
};

export default PuzzleStatus;
