const START_DATE = new Date('2024-06-02').getTime();

const ONE_MINUTE = 60000;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export const getDateForPuzzle = (index: number): Date => {
    return new Date(START_DATE + index * ONE_DAY);
};

export const getPuzzleIndexForDate = (date: Date): number => {
    return Math.floor((date.getTime() - START_DATE) / ONE_DAY);
};

export const getDateString = (date: Date): string => {
    return new Date(date.getTime() - date.getTimezoneOffset() * ONE_MINUTE).toISOString().split('T')[0];
};

export const getNextDateString = (date: Date): string => {
    const nextDate = new Date(date.getTime() + ONE_DAY);
    return getDateString(nextDate);
};

export const getPreviousDateString = (date: Date): string => {
    const previousDate = new Date(date.getTime() - ONE_DAY);
    return getDateString(previousDate);
};
