import React from 'react';
import classNames from 'classnames';

import './ToggleSwitchButton.scss';

interface ToggleSwitchButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    on: boolean;
}

const ToggleSwitchButton = ({ on, ...buttonProps }: ToggleSwitchButtonProps) => {
    return (
        <button
            {...buttonProps}
            className={classNames('ToggleSwitchButton', {
                active: on,
            })}
        >
            <div className="knob" />
        </button>
    );
};

export default ToggleSwitchButton;
