import { getTileScore } from './tileUtils';
import { PuzzleData, Tile } from './types';

type Status = 'incomplete' | 'valid' | 'complete';

export const getPuzzleStatus = (puzzle: PuzzleData): Status => {
    if (puzzle.complete) return 'complete';

    const allMarkersValid = puzzle.matchMarkers.every((marker) => marker.valid);
    if (allMarkersValid) return 'valid';

    return 'incomplete';
};

export const formatPuzzleTime = (timeMs: number): string => {
    const seconds = Math.floor(timeMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const parts = [];

    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes % 60}m`);
    parts.push(`${seconds % 60}s`);

    return parts.join(' ');
};

const calculateMarkerScore = (marker: { tiles: Tile[] }): number => {
    return marker.tiles.reduce((acc, tile) => acc + getTileScore(tile), 0);
};

export const calculateMatchScores = (puzzle: PuzzleData): { score: number; index: number }[] => {
    const scores = puzzle.matchMarkers
        .map((marker, index) => {
            if (!marker.valid) return { score: 0, index };

            return { score: calculateMarkerScore(marker), index };
        })
        .filter(({ score }) => score > 0);

    return scores;
};

export const calculateTotalScore = (puzzle: PuzzleData): number => {
    const scores = calculateMatchScores(puzzle);
    return scores.reduce((acc, { score }) => acc + score, 0);
};
