import React from 'react';

import { MatchMarker } from '../state/types';

import './MatchMarkerHighlight.scss';
import classNames from 'classnames';
import { useDragLayer } from 'react-dnd';

type MatchMarkerHighlightProps = {
    marker: MatchMarker;
    size: number;
    index: number;
};

const MatchMarkerHighlight = ({ marker, size, index }: MatchMarkerHighlightProps): React.ReactElement => {
    const ref = React.useRef<HTMLDivElement>(null);

    const { isDragging } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
    }));

    let inset = size / 4;
    if (marker.valid) {
        inset = size / 6;
    }

    const width = marker.direction === 'horizontal' ? size * marker.length : size;
    const height = marker.direction === 'vertical' ? size * marker.length : size;

    const style = {
        left: marker.x * size + inset,
        top: marker.y * size + inset,
        width: width - inset * 2,
        height: height - inset * 2,
    } as React.CSSProperties;

    return (
        <div
            className={classNames('MatchMarkerHighlight', marker.direction, `marker-${index}`, {
                'dragging-tile': isDragging,
                complete: marker.valid,
            })}
            ref={ref}
            style={style}
        />
    );
};

export default MatchMarkerHighlight;
