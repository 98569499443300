import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from '../icons/icon-close.svg';

import { GameState } from '../state/types';

import './OverlayModal.scss';
import classNames from 'classnames';

type OverlayModalProps = {
    modalId: keyof GameState['modals'];
    close: (modalId: keyof GameState['modals']) => void;
    className?: string;
    title: string;
    children: React.ReactNode;
};

const OverlayModal = ({ title, modalId, className, close, children }: OverlayModalProps): React.ReactElement => {
    const modalRoot = document.getElementById('modal-root') as HTMLElement;

    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 1);
    }, []);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(() => {
            close(modalId);
        }, 300);
    };

    return ReactDOM.createPortal(
        <div
            className={classNames('OverlayModal', className, {
                show,
            })}
            onClick={handleModalClose}
        >
            <div
                className="modal-box"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className="modal-header">
                    <h2>{title}</h2>

                    <button className="close-button" onClick={handleModalClose}>
                        <CloseIcon className="icon" />
                    </button>
                </div>
                <div className="modal-content">{children}</div>
            </div>
        </div>,
        modalRoot,
    );
};

export default OverlayModal;
