import React from 'react';

import LetterTile from './LetterTile';
import MatchMarkerHighlight from './MatchMarkerHighlight';
import { PuzzleData, Tile } from '../state/types';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import './GameBoard.scss';
import LetterTileDragLayer from './LetterTileDragLayer';

type GameBoardProps = {
    puzzle: PuzzleData;
    tileSize: number;
    activeTileId?: string | null;
    boardSize?: number;
    interactive: boolean;
    handleTileTap: (tileId: string) => void;
    handleTileSwap: (sourceId: string, targetId: string) => void;
    handleDragStart: (tileId: string) => void;
};

const GameBoard = ({
    puzzle,
    tileSize,
    activeTileId,
    interactive,
    boardSize = 6,
    handleTileTap,
    handleTileSwap,
    handleDragStart,
}: GameBoardProps) => {
    const { tiles } = puzzle;

    return (
        <DndProvider
            backend={TouchBackend}
            options={{
                enableMouseEvents: true,
            }}
        >
            <LetterTileDragLayer size={tileSize} />
            <div
                className="GameBoard"
                style={{
                    width: tileSize * boardSize,
                    height: tileSize * boardSize,
                    position: 'relative',
                }}
            >
                {tiles.map((tile) => (
                    <LetterTile
                        key={tile.id}
                        tile={tile}
                        active={activeTileId === tile.id}
                        size={tileSize}
                        interactive={interactive && !tile.locked}
                        onTap={() => handleTileTap(tile.id)}
                        onDragDrop={(source: Tile, target: Tile) => handleTileSwap(source.id, target.id)}
                        onDragStart={(tile: Tile) => handleDragStart(tile.id)}
                    />
                ))}

                {puzzle.matchMarkers.map((marker, index) => (
                    <MatchMarkerHighlight key={marker.id} marker={marker} size={tileSize} index={index} />
                ))}
            </div>
        </DndProvider>
    );
};

export default GameBoard;
