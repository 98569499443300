import { generatePuzzleData } from './createPuzzleData';
import { getPuzzleIndexForDate } from '../state/dateUtils';
import { PuzzleData } from '../state/types';

const getWordCounts = (date: Date): number[] => {
    const dayOfWeek = date.getUTCDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6;

    switch (dayOfWeek) {
        case 1: // Monday
            return [6, 6, 5, 4, 4, 3];
        case 2: // Tuesday
            return [5, 5, 4, 4, 4, 3, 3];
        case 3: // Wednesday
            return [6, 5, 4, 4, 4, 3, 3];
        case 4: // Thursday
            return [6, 5, 5, 4, 4, 3, 3];
        case 5: // Friday
            return [6, 5, 5, 5, 4, 4, 3, 3];
        case 6: // Saturday
            return [6, 6, 5, 4, 4, 3, 3, 3, 2];
        case 0: // Sunday
            return [6, 6, 5, 5, 4, 4, 3, 3, 2, 2];
    }
};

const getLockCount = (date: Date): number => {
    const dayOfWeek = date.getUTCDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6;

    switch (dayOfWeek) {
        case 1: // Monday
            return 0;
        case 2: // Tuesday
            return 1;
        case 3: // Wednesday
            return 0;
        case 4: // Thursday
            return 1;
        case 5: // Friday
            return 2;
        case 6: // Saturday
            return 1;
        case 0: // Sunday
            return 2;
    }
};

const getMinimumDifficulty = (date: Date): number => {
    const dayOfWeek = date.getUTCDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6;

    switch (dayOfWeek) {
        case 1: // Monday
            return 6;
        case 2: // Tuesday
            return 6;
        case 3: // Wednesday
            return 7;
        case 4: // Thursday
            return 8;
        case 5: // Friday
            return 8;
        case 6: // Saturday
            return 9;
        case 0: // Sunday
            return 11;
    }
};

export const createPuzzle = (dateString: string): PuzzleData => {
    const puzzleDate = new Date(dateString);

    const index = getPuzzleIndexForDate(puzzleDate);

    let attempt = 0;
    let puzzle: PuzzleData | undefined;

    const seed = puzzleDate.toISOString().split('T')[0];
    const minimumDifficulty = getMinimumDifficulty(puzzleDate);

    do {
        const seedAttempt = `${seed}-${attempt}`;
        const wordCounts = getWordCounts(puzzleDate);
        const lockCount = getLockCount(puzzleDate);
        const generatedPuzzle = generatePuzzleData(seedAttempt, wordCounts, lockCount, {
            date: puzzleDate,
            index,
        });

        if (!puzzle || generatedPuzzle.difficulty > puzzle.difficulty) {
            puzzle = generatedPuzzle;
        }

        attempt++;
    } while (puzzle.difficulty < minimumDifficulty);

    return puzzle;
};
