import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Tile } from '../state/types';
import { ReactComponent as LockIcon } from '../icons/icon-lock.svg';

import './LetterTile.scss';
import { useDrag, useDrop } from 'react-dnd';
import { getTileScore } from '../state/tileUtils';

type LetterTileProps = {
    tile: Tile;
    active: boolean;
    size: number;
    interactive: boolean;
    onTap: () => void;
    onDragDrop: (source: Tile, target: Tile) => void;
    onDragStart: (tile: Tile) => void;
};

const LetterTile = ({
    tile,
    active,
    size,
    interactive,
    onTap,
    onDragDrop,
    onDragStart,
}: LetterTileProps): React.ReactElement => {
    const inset = 1;
    const fontSize = size * 0.29;

    const [rotation, setRotation] = useState(0);
    useEffect(() => {
        if (active) {
            setRotation(Math.random() * 10 - 5);
        } else {
            setRotation(0);
        }
    }, [active]);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'tile',
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: () => {
            onDragStart(tile);
            return { type: 'tile', tile };
        },
    }));

    const [{ isHovered }, drop] = useDrop(() => ({
        accept: 'tile',
        canDrop: (item, monitor) => {
            const draggedItem = item as { tile: Tile };
            return draggedItem.tile.id !== tile.id;
        },
        drop: (item) => {
            const draggedItem = item as { tile: Tile };

            onDragDrop(tile, draggedItem.tile);
        },
        collect: (monitor) => ({
            isHovered: !!monitor.isOver(),
        }),
    }));

    const style = {
        left: tile.x * size + inset,
        top: tile.y * size + inset,
        '--tile-size': `${size - inset * 2}px`,
        '--font-size': `${fontSize}px`,
        '--active-rotation': `${rotation}deg`,
    } as React.CSSProperties;

    return (
        <div
            ref={(c) => {
                if (!interactive) return;
                drag(drop(c));
            }}
            className={classNames('LetterTile', {
                active,
                dragging: isDragging,
                hovered: isHovered,
                interactive,
                locked: tile.locked,
            })}
            style={style}
            onClick={() => {
                if (!interactive) return;
                onTap();
            }}
        >
            <div className="letter">{tile.letter}</div>
            <div className="score">{tile.locked ? <LockIcon /> : getTileScore(tile)}</div>
        </div>
    );
};

export default LetterTile;
