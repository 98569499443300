import classNames from 'classnames';

import { calculateMatchScores, calculateTotalScore } from '../state/puzzleUtils';

import { PuzzleData } from '../state/types';

import './PuzzleScores.scss';

type PuzzleScoresProps = {
    puzzle: PuzzleData;
};

const PuzzleScores = ({ puzzle }: PuzzleScoresProps): React.ReactElement => {
    const markerScores = calculateMatchScores(puzzle);
    const totalScore = calculateTotalScore(puzzle);

    return (
        <div className="PuzzleScores">
            {markerScores.map(({ score, index }) => (
                <div key={index} className={classNames('score', `marker-${index}`)}>
                    {score}
                </div>
            ))}

            {totalScore > 0 && <div className="score total">{totalScore}</div>}
        </div>
    );
};

export default PuzzleScores;
