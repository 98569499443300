import { useEffect, useState } from 'react';

import { GameHistoryData, getGameHistoryData } from '../gameHistory/gameHistoryUtils';

const useGameHistoryStats = (): GameHistoryData | null => {
    const [stats, setStats] = useState<GameHistoryData | null>(null);

    useEffect(() => {
        getGameHistoryData().then(setStats);
    }, []);

    return stats;
};

export default useGameHistoryStats;
