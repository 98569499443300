import { MatchMarker, Tile } from '../state/types';

const findIntersectingTiles = (matchMarkers: MatchMarker[]): Tile[] => {
    return matchMarkers
        .flatMap((marker) => marker.tiles)
        .filter((tile) => {
            // filter out tiles that are part of only one match
            const matches = matchMarkers.filter((marker) => marker.tiles.some((t) => t.x === tile.x && t.y === tile.y));
            return matches.length > 1;
        })
        .filter((tile, index, self) => {
            // filter out duplicate tiles
            return self.findIndex((t) => t.x === tile.x && t.y === tile.y) === index;
        });
};

export const calculatePuzzleDifficulty = (tiles: Tile[], matchTiles: Tile[], matchMarkers: MatchMarker[]): number => {
    const intersections = findIntersectingTiles(matchMarkers).length;

    return intersections;
};
