import { EMPTY_TILE, GRID_WIDTH } from './gameConstants';
import { Tile } from './types';

/**
 * Returns the tile at the given position, or undefined if there is no tile there.
 */
export const getTileAt = (tiles: Tile[], x: number, y: number): Tile | undefined => {
    return tiles.find((tile) => tile.x === x && tile.y === y);
};

export const getTilesInRow = (tiles: Tile[], x: number, y: number, maxLength: number = 6): Tile[] => {
    const horizontalTiles: Tile[] = [];

    const max = Math.min(x + maxLength, GRID_WIDTH);

    for (let i = x; i < max; i++) {
        const tile = getTileAt(tiles, i, y);

        if (!tile || tile.letter === EMPTY_TILE) {
            break;
        }

        horizontalTiles.push(tile);
    }

    return horizontalTiles;
};

export const getTilesInColumn = (tiles: Tile[], x: number, y: number, maxLength: number = 6): Tile[] => {
    const verticalTiles: Tile[] = [];

    const max = Math.min(y + maxLength, GRID_WIDTH);

    for (let i = y; i < max; i++) {
        const tile = getTileAt(tiles, x, i);

        if (!tile || tile.letter === EMPTY_TILE) {
            break;
        }

        verticalTiles.push(tile);
    }

    return verticalTiles;
};

type ValidLetter =
    | 'a'
    | 'b'
    | 'c'
    | 'd'
    | 'e'
    | 'f'
    | 'g'
    | 'h'
    | 'i'
    | 'j'
    | 'k'
    | 'l'
    | 'm'
    | 'n'
    | 'o'
    | 'p'
    | 'q'
    | 'r'
    | 's'
    | 't'
    | 'u'
    | 'v'
    | 'w'
    | 'x'
    | 'y'
    | 'z';

/* based on letter frequency in the English language
    - etaoinsr: 1 point
    - hldcu: 2 points
    - mfpg: 3 points
    - wyb: 4 points
    - vkx: 6 points
    - jqz: 9 points
*/

export const getTileScore = (tile: Tile): number => {
    if (tile.locked) return 0;

    switch (tile.letter as ValidLetter) {
        case 'e':
        case 't':
        case 'a':
        case 'o':
        case 'i':
        case 'n':
        case 's':
        case 'r':
            return 1;
        case 'h':
        case 'l':
        case 'd':
        case 'c':
        case 'u':
            return 2;
        case 'm':
        case 'f':
        case 'p':
        case 'g':
            return 3;
        case 'w':
        case 'y':
        case 'b':
            return 4;
        case 'v':
        case 'k':
        case 'x':
            return 6;
        case 'j':
        case 'q':
        case 'z':
            return 9;
        default:
            return 0;
    }
};
