import { useEffect, useRef } from 'react';
import { Action } from '../state/types';

/**
 * Time the user has been playing the game.
 * - Automatically starts and stops based on the `running` parameter.
 * - Pauses when in a background tab.
 */
const useGameTimer = (running: boolean, time: number, dispatch: React.Dispatch<Action>) => {
    const lastTimeRef = useRef<number>(new Date().getTime());
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const timeRef = useRef(time);

    useEffect(() => {
        timeRef.current = time;
    }, [time]);

    useEffect(() => {
        if (!running) {
            if (timeoutRef.current) clearInterval(timeoutRef.current);
            return;
        }

        const tick = () => {
            const now = new Date().getTime();
            const elapsed = now - lastTimeRef.current;
            lastTimeRef.current = now;

            // if not active, don't update the time
            if (document.hidden) return;

            dispatch({ type: 'tick_timer', increment: elapsed, time: timeRef.current + elapsed });
        };

        timeoutRef.current = setInterval(tick, 1000);

        return () => {
            if (timeoutRef.current) clearInterval(timeoutRef.current);
        };
    }, [running, dispatch]);
};

export default useGameTimer;
