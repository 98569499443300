import React, { useEffect } from 'react';
import OverlayModal from '../components/OverlayModal';
import GameBoard from '../components/GameBoard';
import StyledButton from '../components/StyledButton';
import { GameState, PuzzleData } from '../state/types';

import { ReactComponent as LockIcon } from '../icons/icon-lock.svg';

import './HelpModal.scss';
import useGameState from '../state/useGameState';

const EXAMPLE_PUZZLE: PuzzleData = {
    seed: 'help',
    difficulty: 0,
    complete: false,
    time: 0,
    matchMarkers: [
        {
            id: 'm0',
            x: 0,
            y: 0,
            length: 4,
            word: '____',
            direction: 'horizontal',
            tiles: [],
        },
        {
            id: 'm1',
            x: 1,
            y: 0,
            length: 4,
            word: '____',
            direction: 'vertical',
            tiles: [],
        },
        {
            id: 'm2',
            x: 1,
            y: 2,
            length: 3,
            word: '___',
            direction: 'horizontal',
            tiles: [],
        },
    ],
    info: {
        date: new Date(),
        index: 0,
    },
    tiles: [
        {
            id: '0',
            letter: 's',
            x: 0,
            y: 0,
        },
        {
            id: '1',
            letter: 'd',
            x: 1,
            y: 0,
        },
        {
            id: '2',
            letter: 'a',
            x: 2,
            y: 0,
        },
        {
            id: '3',
            letter: 'p',
            x: 3,
            y: 0,
        },
        {
            id: '4',
            letter: 'a',
            x: 0,
            y: 1,
        },
        {
            id: '5',
            letter: 'o',
            x: 1,
            y: 1,
            locked: true,
        },
        {
            id: '6',
            letter: 'n',
            x: 2,
            y: 1,
        },
        {
            id: '7',
            letter: 'c',
            x: 3,
            y: 1,
        },
        {
            id: '8',
            letter: 'i',
            x: 0,
            y: 2,
        },
        {
            id: '9',
            letter: 'r',
            x: 1,
            y: 2,
        },
        {
            id: '10',
            letter: 'w',
            x: 2,
            y: 2,
        },
        {
            id: '11',
            letter: 'u',
            x: 3,
            y: 2,
        },
        {
            id: '12',
            letter: 'z',
            x: 0,
            y: 3,
        },
        {
            id: '13',
            letter: 'd',
            x: 1,
            y: 3,
        },
        {
            id: '14',
            letter: 'l',
            x: 2,
            y: 3,
        },
        {
            id: '15',
            letter: 'e',
            x: 3,
            y: 3,
        },
    ],
};

type HelpModalProps = {
    handleCloseModal: (modalId: keyof GameState['modals']) => void;
};

const HelpModal = ({ handleCloseModal }: HelpModalProps): React.ReactElement => {
    const [state, dispatch] = useGameState(false);

    useEffect(() => {
        let timeouts: NodeJS.Timeout[] = [];

        dispatch({ type: 'set_puzzle', puzzle: EXAMPLE_PUZZLE });

        const animateExample = () => {
            timeouts = [
                setTimeout(() => {
                    dispatch({
                        type: 'select_tile',
                        tileId: '10',
                    });
                }, 500),

                setTimeout(() => {
                    dispatch({
                        type: 'select_tile',
                        tileId: null,
                    });
                    dispatch({
                        type: 'swap_tile',
                        sourceId: '1',
                        targetId: '10',
                    });
                }, 1000),

                setTimeout(() => {
                    dispatch({
                        type: 'select_tile',
                        tileId: '1',
                    });
                }, 2000),

                setTimeout(() => {
                    dispatch({
                        type: 'select_tile',
                        tileId: null,
                    });
                    dispatch({
                        type: 'swap_tile',
                        sourceId: '1',
                        targetId: '10',
                    });
                }, 2500),

                setTimeout(() => {
                    animateExample();
                }, 3500),
            ];
        };

        animateExample();

        return () => {
            timeouts.forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
    }, [dispatch]);

    return (
        <OverlayModal title="How to play Gingham" modalId="help" close={handleCloseModal} className="HelpModal">
            <p className="goal">Complete all the highlighted words.</p>

            <p>
                <strong>Tap</strong> or <strong>drag</strong> tiles to swap them.
            </p>

            <p>
                <LockIcon className="explain-icon" />
                <strong>Locked</strong> tiles can't be moved.
            </p>

            <p>There's no correct answer and many ways to win.</p>

            <div className="small-board-wrapper">
                <div className="small-board-container">
                    <GameBoard
                        puzzle={state.puzzle}
                        tileSize={64}
                        boardSize={4}
                        activeTileId={state.activeTileId}
                        interactive={false}
                        handleTileTap={() => {}}
                        handleTileSwap={() => {}}
                        handleDragStart={() => {}}
                    />
                </div>
            </div>

            <p>A new puzzle is released every day at midnight.</p>

            <p>
                <StyledButton onClick={() => handleCloseModal('help')}>Play now!</StyledButton>
            </p>
        </OverlayModal>
    );
};

export default HelpModal;
