import React from 'react';

import { PuzzleData } from '../state/types';

import './PuzzleDescription.scss';

const PuzzleDescription = ({ puzzle }: { puzzle: PuzzleData }) => {
    const formattedDate = new Date(puzzle.info?.date || new Date()).toLocaleDateString(navigator.languages, {
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        year: 'numeric',
    });

    return (
        <div className="PuzzleDescription">
            <div className="number">Puzzle no. {puzzle.info?.index}</div>
            <div className="date">{formattedDate}</div>
        </div>
    );
};

export default PuzzleDescription;
