import React from 'react';

import OverlayModal from '../components/OverlayModal';
import GameBoard from '../components/GameBoard';
import PuzzleDescription from '../components/PuzzleDescription';
import PuzzleScores from '../components/PuzzleScores';
import GameHistoryDays from '../components/GameHistoryDays';

import useGameHistoryStats from '../hooks/useGameHistoryStats';
import { formatPuzzleTime } from '../state/puzzleUtils';

import { GameState } from '../state/types';

import './VictoryModal.scss';

type VictoryModalProps = {
    gameState: GameState;
    gameTime: number;
    handleCloseModal: (modalId: keyof GameState['modals']) => void;
};

const TimeDisplay = ({ time }: { time: number }): React.ReactElement => {
    return <span className="time">{formatPuzzleTime(time)}</span>;
};

const VictoryModal = ({ gameState, gameTime, handleCloseModal }: VictoryModalProps): React.ReactElement | null => {
    const { puzzle } = gameState;

    const stats = useGameHistoryStats();

    return (
        <OverlayModal
            className="VictoryModal"
            modalId="victory"
            close={handleCloseModal}
            title={`Puzzle no. ${puzzle.info.index}`}
        >
            <PuzzleDescription puzzle={puzzle} />

            <div className="small-board-wrapper">
                <div className="small-board-container">
                    <GameBoard
                        puzzle={puzzle}
                        tileSize={38}
                        boardSize={6}
                        activeTileId={null}
                        interactive={false}
                        handleTileTap={() => {}}
                        handleTileSwap={() => {}}
                        handleDragStart={() => {}}
                    />
                </div>
            </div>

            <PuzzleScores puzzle={puzzle} />

            <p>
                Completed in <TimeDisplay time={gameTime} />
            </p>

            {stats && (
                <div className="game-history-stats">
                    <h3>This week</h3>
                    <GameHistoryDays gameHistoryData={stats} numberOfDays={7} />
                </div>
            )}
        </OverlayModal>
    );
};

export default VictoryModal;
