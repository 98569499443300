import React from 'react';
import OverlayModal from '../components/OverlayModal';

import { GameState } from '../state/types';
import { formatPuzzleTime } from '../state/puzzleUtils';

import './StatsModal.scss';
import GameHistoryDays from '../components/GameHistoryDays';
import useGameHistoryStats from '../hooks/useGameHistoryStats';

type HelpModalProps = {
    handleCloseModal: (modalId: keyof GameState['modals']) => void;
};

const StatsRow = ({
    label,
    values,
}: {
    label: string;
    values: { label: string; value: string | number }[];
}): React.ReactElement => {
    return (
        <div className="stats-row">
            <div className="stats-title">{label}</div>
            {values.map(({ label, value }) => (
                <div key={label} className="sub-stats-row">
                    <div className="stats-label">{label}</div>
                    <div className="stats-value">{value}</div>
                </div>
            ))}
        </div>
    );
};

const StatsModal = ({ handleCloseModal }: HelpModalProps): React.ReactElement | null => {
    const stats = useGameHistoryStats();
    if (!stats) return null;

    return (
        <OverlayModal title="Puzzle history" modalId="stats" close={handleCloseModal} className="StatsModal">
            <div className="stats-row">
                <div className="stats-title">This week</div>
                <GameHistoryDays gameHistoryData={stats} numberOfDays={7} />
            </div>

            <StatsRow
                label="Best results"
                values={[
                    {
                        label: 'Highest score',
                        value: `${stats.bestResults.highestScore.score} (no. ${stats.bestResults.highestScore.index})`,
                    },
                    {
                        label: 'Fastest completion',
                        value: `${formatPuzzleTime(stats.bestResults.fastestCompletion.time)} (no. ${stats.bestResults.fastestCompletion.index})`,
                    },
                ]}
            />

            <StatsRow
                label="Streaks"
                values={[
                    {
                        label: 'Current',
                        value: stats.streaks.current,
                    },
                    {
                        label: 'Longest',
                        value: stats.streaks.longest,
                    },
                ]}
            />

            <StatsRow
                label="All time"
                values={[
                    {
                        label: 'Puzzles completed',
                        value: stats.totals.puzzlesCompleted,
                    },
                    {
                        label: 'Words completed',
                        value: stats.totals.matchesCompleted,
                    },
                    {
                        label: 'Tiles used',
                        value: stats.totals.tilesUsed,
                    },
                    {
                        label: 'Points scored',
                        value: stats.totals.pointsScored,
                    },
                ]}
            />
        </OverlayModal>
    );
};

export default StatsModal;
